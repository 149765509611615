import { useContext, useEffect, useState } from 'react';
import { ActionContext } from '../../ActionProvider';
import JSONWrapper from './WrapperForJSON';

const JSONView = () => {
  const actions = useContext(ActionContext);

  const [jsonRAW, setjsonRAW] = useState<any>(undefined);

  useEffect(() => {
    switch (actions.settings.viewType) {
      case 'scancode':
        setjsonRAW([...actions.scancode.jsonRAW.files]);
        break;

      case 'object_02':
        setjsonRAW([...actions.scancode.jsonRAW]);
        break;

      case 'object_01':
        setjsonRAW({ ...actions.scancode.jsonRAW });
        break;

      default:
        break;
    }
  }, []);

  return <>{jsonRAW && <JSONWrapper jsonRAW={jsonRAW} />}</>;
};

export default JSONView;
