import { FileUpload } from '../components/FileUpload';

const UploadPage = () => {
  return (
    <div style={{ textAlign: 'center', paddingTop: 20 }}>
      <FileUpload />
    </div>
  );
};

export default UploadPage;
