import { useContext } from 'react';
import { ActionContext } from '../../ActionProvider';
import ReactJson from 'react-json-view';

const JSONWrapper = jsonRAW => {
  const actions = useContext(ActionContext);
  const themeForView = actions.system.settings.theme === 'dark' ? 'summerfruit' : 'summerfruit:inverted';

  return <>{jsonRAW && <ReactJson enableClipboard displayDataTypes={false} theme={themeForView} collapsed src={jsonRAW} />}</>;
};

export default JSONWrapper;
