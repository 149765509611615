import { createContext, useContext, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import 'react-data-grid/lib/styles.css';
import { ActionContext } from '../../ActionProvider';
import DataGrid, { Column, RenderHeaderCellProps, SortColumn } from 'react-data-grid';
import Highlighter from 'react-highlight-words';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Tooltip, styled, useMediaQuery, useTheme } from '@mui/material';

import DescriptionIcon from '@mui/icons-material/Description';
import FolderIcon from '@mui/icons-material/Folder';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import getComparator from './sortFunctions';
import JSONWrapper from '../JSONView/WrapperForJSON';

interface Row {
  md5: string;
  path: string;
  copyrights: Array<any>;
  license_detections: Array<any>;
  [x: string]: any;
}

interface Filter extends Omit<Row, 'md5' | 'copyrights' | 'license_detections'> {}

// Context is needed to read filter values otherwise columns are
// re-created when filters are changed and filter loses focus
const FilterContext = createContext<Filter | undefined>(undefined);

function inputStopPropagation(event: React.KeyboardEvent<HTMLInputElement>) {
  if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
    event.stopPropagation();
  }
}

function selectStopPropagation(event: React.KeyboardEvent<HTMLSelectElement>) {
  if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
    event.stopPropagation();
  }
}

const rowDefaultHeight = 30;

const PREFIX = 'StyledTable';
const classes = {
  root: `${PREFIX}-root`,
  rowCustomRender: `${PREFIX}-rowCustomRender`,
  rowCustomCell: `${PREFIX}-rowCustomCell`,
  filterColumnClassName: `${PREFIX}-filterColumnClassName`,
  filterContainerClassname: `${PREFIX}-filterContainerClassname`,
  filterClassname: `${PREFIX}-filterClassname`,
  highlightResults: `${PREFIX}-highlightResults`
};

const StyledTable = styled('div')(({ theme }) => {
  return {
    [`&.${classes.root}`]: {},
    [`& .${classes.rowCustomRender}`]: {
      marginTop: 5,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      lineHeight: 'normal',
      height: '100%',
      overflow: 'auto'
    },
    [`& .${classes.rowCustomCell}`]: {
      height: rowDefaultHeight
    },
    [`& .${classes.filterColumnClassName}`]: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      '& div': {
        lineHeight: rowDefaultHeight + 'px'
      },
      '& span': {
        lineHeight: rowDefaultHeight + 'px'
      }
    },
    [`& .${classes.filterContainerClassname}`]: {
      lineHeight: 35,
      padding: 0
    },
    [`& .${classes.filterClassname}`]: {
      padding: 4,
      fontSize: 14
    },
    [`& .${classes.highlightResults}`]: {
      backgroundColor: '#F2BB05'
    }
  };
});

function TableViewObjectV01({ direction }: any) {
  const actions = useContext(ActionContext);

  const [rows] = useState(() => {
    let newState = [];
    for (const [key, value] of Object.entries(actions.scancode.jsonRAW)) {
      newState.push({ key: key as string, ...(value as any) });
    }
    return newState;
  });

  const [open, setOpen] = useState({ open: false, data: undefined });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = data => {
    setOpen({ open: true, data: data });
  };

  const handleClose = () => {
    setOpen({ open: false, data: undefined });
  };

  //DataPoints
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [filters, setFilters] = useState(
    (): Filter => ({
      path: ''
    })
  );

  const columns = useMemo((): readonly Column<Row>[] => {
    return [
      { key: 'key', headerCellClass: classes.filterColumnClassName, name: 'ID', width: 250, resizable: true },
      {
        key: 'repository',
        headerCellClass: classes.filterColumnClassName,
        name: 'Repository',
        width: 250,
        resizable: true,
        renderCell({ row, tabIndex }) {
          return (
            <a href={row.repository} target="_blank" rel="noreferrer">
              {row.repository}
            </a>
          );
        }
      },
      {
        key: 'path',
        name: 'Dateipfad',
        resizable: true,
        sortable: true,
        headerCellClass: classes.filterColumnClassName,
        renderHeaderCell: p => (
          <FilterRenderer<Row> {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                className={classes.filterClassname}
                value={filters.path}
                onChange={e =>
                  setFilters({
                    ...filters,
                    path: e.target.value
                  })
                }
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
        renderCell({ row, tabIndex }) {
          return <Highlighter highlightClassName={classes.highlightResults} searchWords={[filters.path]} autoEscape={true} textToHighlight={row.path} />;
        }
      },
      {
        key: 'copyright',
        name: 'Copyright',
        resizable: true,
        sortable: true,
        headerCellClass: classes.filterColumnClassName
      },
      {
        key: 'licenses',
        name: 'Lizenz',
        resizable: true,
        sortable: true,
        headerCellClass: classes.filterColumnClassName
      },
      {
        key: 'options',
        name: '',
        width: 80,
        headerCellClass: classes.filterColumnClassName,
        renderCell({ row, tabIndex }) {
          return (
            <div key={row.sha1 + '-cell-options'} className={classes.rowCustomRender}>
              <Button
                variant="contained"
                size="small"
                sx={{ height: rowDefaultHeight - 15 }}
                onClick={() => {
                  handleClickOpen(row);
                }}
              >
                Details
              </Button>
            </div>
          );
        }
      }
    ];
  }, [filters]);

  function rowKeyGetter(row) {
    return row.id;
  }

  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0) return rows;

    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);

        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [rows, sortColumns]);

  const filteredRows = useMemo(() => {
    return sortedRows.filter(r => {
      return filters.path ? r.path.toLowerCase().includes(filters.path.toLowerCase()) : true;
    });
  }, [sortedRows, filters]);

  function clearFilters() {
    setFilters({
      path: ''
    });
  }

  return (
    <>
      <Dialog fullScreen={fullScreen} open={open.open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">Details dieser Zeile</DialogTitle>
        <DialogContent>{open.data && <JSONWrapper jsonRAW={open.data} />}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Fertig
          </Button>
        </DialogActions>
      </Dialog>
      <StyledTable className={classes.root}>
        <FilterContext.Provider value={filters}>
          <DataGrid
            className={actions.system.settings.theme === 'dark' ? 'rdg-dark' : 'rdg-light'}
            style={{ height: '80vh' }}
            columns={columns}
            rows={filteredRows}
            rowKeyGetter={rowKeyGetter}
            rowHeight={rowDefaultHeight}
            headerRowHeight={70}
            sortColumns={sortColumns}
            onSortColumnsChange={setSortColumns}
            direction={direction}
            defaultColumnOptions={{ width: '1fr' }}
            renderers={{ renderSortStatus }}
          />
        </FilterContext.Provider>
      </StyledTable>
    </>
  );
}

function renderSortStatus({ sortDirection, priority }) {
  return (
    <>
      {sortDirection !== undefined ? (sortDirection === 'ASC' ? '\u2B9D' : '\u2B9F') : null}
      <span
        style={{
          color: 'grey',
          marginLeft: 2
        }}
      >
        {priority}
      </span>
    </>
  );
}

function FilterRenderer<R>({
  tabIndex,
  column,
  children
}: RenderHeaderCellProps<R> & {
  children: (args: { tabIndex: number; filters: Filter }) => React.ReactElement;
}) {
  const filters = useContext(FilterContext)!;
  return (
    <>
      <div onClick={() => {}}>{column.name}</div>
      <div>Filtern: {children({ tabIndex, filters })}</div>
    </>
  );
}

export default TableViewObjectV01;
