export type Comparator = (a, b) => number;
function getComparator(sortColumn: string): Comparator {
  switch (sortColumn) {
    case 'path':
      return (a, b) => {
        if (a['path'] && b['path']) {
          if (a['path'] < b['path']) {
            return -1;
          }
          if (!a['path'] > b['path']) {
            return 1;
          }
        }

        return 0;
      };

    case 'license_detections':
      return (a, b) => {
        if (a['license_detections'].length && b['license_detections'].length) {
          if (a['license_detections'].length === 1 && b['license_detections'].length.length === 1) {
            if (a['license_detections'][0]['license_expression'] < b['license_detections'][0]['license_expression']) {
              return -1;
            }
            if (a['license_detections'][0]['license_expression'] > b['license_detections'][0]['license_expression']) {
              return 1;
            }
          }
          if (a['license_detections'].length > 1 || b['license_detections'].length > 1) {
            if (a['license_detections'][0]['license_expression'] < b['license_detections'][0]['license_expression']) {
              return -1;
            }
            if (a['license_detections'][0]['license_expression'] > b['license_detections'][0]['license_expression']) {
              return 1;
            }
          }
        }

        if (a['license_detections'].length && !b['license_detections'].length) {
          return -1;
        }
        if (!a['license_detections'].length && b['license_detections'].length) {
          return 1;
        }

        return 0;
      };

    case 'copyrights':
      return (a, b) => {
        if (a['copyrights'].length && b['copyrights'].length) {
          if (a['copyrights'].length === 1 && b['copyrights'].length.length === 1) {
            if (a['copyrights'][0]['copyright'] < b['copyrights'][0]['copyright']) {
              return -1;
            }
            if (a['copyrights'][0]['copyright'] > b['copyrights'][0]['copyright']) {
              return 1;
            }
          }
          if (a['copyrights'].length > 1 || b['copyrights'].length > 1) {
            if (a['copyrights'][0]['copyright'] < b['copyrights'][0]['copyright']) {
              return -1;
            }
            if (a['copyrights'][0]['copyright'] > b['copyrights'][0]['copyright']) {
              return 1;
            }
          }
        }

        if (a['copyrights'].length && !b['copyrights'].length) {
          return -1;
        }
        if (!a['copyrights'].length && b['copyrights'].length) {
          return 1;
        }

        return 0;
      };

    default:
      throw new Error(`unsupported sortColumn: "${sortColumn}"`);
  }
}

export default getComparator;
