import { FunctionComponent, useContext } from 'react';
import { Button } from '@mui/material';
import { JSONTryParse } from '../helpers/tryJSONparse';
import { ActionContext } from '../ActionProvider';

export const FileUpload: FunctionComponent = () => {
  const actions = useContext(ActionContext);

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.readAsText(file);

    reader.addEventListener(
      'load',
      () => {
        // this will then display a text file
        const res = JSONTryParse(reader.result);
        if (res) {
          actions.scancode.setjsonRAW(res);
          actions.system.messages.showMessage('success', 'Erfolgreich importiert');
          actions.settings.setCurrentView('result');
        }
      },
      false
    );
  };

  return (
    <>
      <label htmlFor="upload-image">
        <Button variant="contained" component="span">
          Upload
        </Button>
        <input id="upload-image" hidden accept="application/JSON" type="file" onChange={handleFileUpload} />
      </label>
    </>
  );
};
