import { SyntheticEvent, useContext, useState } from 'react';
import { ActionContext } from '../ActionProvider';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import JSONView from '../components/JSONView/JSONView';
import TableViewScancode from '../components/TableViewScancode/TableViewScancode';
import TableViewObjectV01 from '../components/TableViewObjectV01/TableViewObjectV01';
import TableViewObjectV02 from '../components/TableViewObjectV02/TableViewObjectV02';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" style={{ height: '100%' }} hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const ResultPage = () => {
  const actions = useContext(ActionContext);

  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Tabelle" {...a11yProps(0)} />
          <Tab label="Baumansicht" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <>
          {actions.settings.viewType === 'object_01' && <TableViewObjectV01 />}
          {actions.settings.viewType === 'object_02' && <TableViewObjectV02 />}
          {actions.settings.viewType === 'scancode' && <TableViewScancode />}
          {!actions.settings.viewType && <Typography variant="caption">Nicht unterstütztes Format</Typography>}
        </>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <>
          <JSONView />
        </>
      </CustomTabPanel>
    </Box>
  );
};

export default ResultPage;
