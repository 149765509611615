import { useContext, useEffect } from 'react';
import './App.css';
import { ActionContext } from './ActionProvider';

import UploadPage from './pages/Upload';
import ResultPage from './pages/Result';
import ResponsiveAppBar from './components/AppBar';

function App() {
  const actions = useContext(ActionContext);

  useEffect(() => {
    document.title = 'FOSS-Checker';
  }, []);

  return (
    <div className="App">
      <ResponsiveAppBar />
      <body>
        {actions.settings.currentView === 'upload' && <UploadPage />}
        {actions.settings.currentView === 'result' && <ResultPage />}
      </body>
    </div>
  );
}

export default App;
