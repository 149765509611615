import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ActionProvider } from './ActionProvider';
import { CssBaseline, ThemeProvider, createTheme, useMediaQuery } from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  }
});
const lightTheme = createTheme({
  palette: {
    mode: 'light'
  }
});

const AppWrapper = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  return (
    <ThemeProvider theme={prefersDarkMode ? darkTheme : lightTheme}>
      <CssBaseline enableColorScheme />
      <ActionProvider initialState={{ theme: prefersDarkMode ? 'dark' : 'light' }}>
        <App />
      </ActionProvider>
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <AppWrapper />
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
