export type Comparator = (a, b) => number;
function getComparator(sortColumn: string): Comparator {
  switch (sortColumn) {
    case 'dependency':
      return (a, b) => {
        const f = 'dependency';
        if (a[f] && b[f]) {
          if (a[f] < b[f]) {
            return -1;
          }
          if (!a[f] > b[f]) {
            return 1;
          }
        }

        return 0;
      };

    case 'licenses':
      return (a, b) => {
        const f = 'licenses';
        if (a[f] && b[f]) {
          if (a[f] < b[f]) {
            return -1;
          }
          if (!a[f] > b[f]) {
            return 1;
          }
        }

        return 0;
      };

    default:
      throw new Error(`unsupported sortColumn: "${sortColumn}"`);
  }
}

export default getComparator;
