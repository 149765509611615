export const JSONTryParse = (input: any) => {
  try {
    //check if the string exists
    if (input) {
      var o = JSON.parse(input);

      //validate the result too
      if (o && (o.constructor === Object || o.constructor === Array)) {
        return o;
      }
    }
  } catch (e: any) {}

  return undefined;
};
